import { createSlice } from "@reduxjs/toolkit";
import { userService } from "../services/users.service";
import { openSnackBar } from "./snackBarReducer";
import { logout } from "./authReducer";

//reducers
export const userSlice = createSlice({
  name: "user",
  initialState: {
    updatingUser: false,
    deletingUser: false,
    gettingUserData: true,
    user: {},
    get_user: {},
    artworks: [],
    submitted: 0,
    collectorTooltip: 0,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUpdatingUser: (state, action) => {
      state.updatingUser =
        action.payload === undefined ? false : action.payload;
    },
    setGettingUserData: (state, action) => {
      state.gettingUserData =
        action.payload === undefined ? false : action.payload;
    },
    setDeletingUser: (state, action) => {
      state.deletingUser =
        action.payload === undefined ? false : action.payload;
    },
    setGetUser: (state, action) => {
      state.get_user = action.payload;
    },
    setArtworks: (state, action) => {
      state.artworks = action.payload === undefined ? [] : action.payload;
    },
    setSubmittedArtworks: (state, action) => {
      state.submitted = action.payload;
    },
    setCollectorTooltip: (state, action) => {
      state.collectorTooltip = action.payload;
    },
  },
});

//actions
export const {
  setUser,
  setUpdatingUser,
  setGettingUserData,
  setDeletingUser,
  setGetUser,
  setArtworks,
  setSubmittedArtworks,
  setCollectorTooltip,
} = userSlice.actions;

export const getUserData = (userId) => async (dispatch) => {
  dispatch(setGettingUserData(true));
  try {
    const userData = await userService.getUserById(userId);
    dispatch(setUser(userData.data.user));
    dispatch(setGettingUserData(false));
  } catch (error) {
    dispatch(logout());
    dispatch(setUser({}));
    dispatch(setGettingUserData(false));
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const getSubmittedArtworks = () => async (dispatch) => {
  dispatch(setSubmittedArtworks(0));
  try {
    const userData = await userService.getSubmittedArtworks();
    dispatch(setSubmittedArtworks(userData.submitted));
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const getUserById = (id) => async (dispatch) => {
  try {
    const userData = await userService.getUserById(id);
    dispatch(setGetUser(userData.data.user));
  } catch (error) {
    dispatch(setGetUser({}));
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const getArtistData = (url) => async (dispatch) => {
  try {
    const userData = await userService.getUserByUrl(url);
    dispatch(setGetUser(userData.data.user));
  } catch (error) {
    dispatch(setGetUser({}));
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const getUserArtworks = (userId) => async (dispatch) => {
  try {
    const artworksData = await userService.getUserArtworks(userId);
    dispatch(setArtworks(artworksData.artworks));
  } catch (error) {
    dispatch(setArtworks([]));
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const submitForVote = (userId, status) => async (dispatch) => {
  dispatch(setUpdatingUser(true));

  try {
    const respData = await userService.submitForVote(userId, status);
    dispatch(setUpdatingUser(false));
    if (respData.status === "success") {
      dispatch(setUser(respData.user));
      dispatch(
        openSnackBar({
          message: `Successfully ${
            status ? "submit for the vote" : "request for the edit"
          }.`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(setUpdatingUser(false));
    dispatch(openSnackBar({ message: error.message, status: "error" }));
    throw new Error(error);
  }
};

export const updateUserData =
  (userId, user, is_artist = true) =>
  async (dispatch) => {
    dispatch(setUpdatingUser(true));
    try {
      const respData = await userService.updateUser(userId, user);
      dispatch(setUpdatingUser(false));
      if (respData.status === "success") {
        if (is_artist) {
          dispatch(setUser(respData.user));
        }
        dispatch(
          openSnackBar({
            message: `Successfully updated user data!`,
            status: "success",
          })
        );
      }
    } catch (error) {
      dispatch(setUpdatingUser(false));
      dispatch(openSnackBar({ message: error.message, status: "error" }));
      throw new Error(error);
    }
  };

export const updateUserDataBy = (userId, user) => async (dispatch) => {
  dispatch(setUpdatingUser(true));
  try {
    const respData = await userService.updateUser(userId, user);
    dispatch(setUpdatingUser(false));
    if (respData.status === "success") {
      dispatch(setGetUser(respData.user));
      dispatch(
        openSnackBar({
          message: `Successfully updated user data!`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(setUpdatingUser(false));
    dispatch(openSnackBar({ message: error.message, status: "error" }));
    throw new Error(error);
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  dispatch(setDeletingUser(true));

  try {
    await userService.deleteUser(userId);
    dispatch(setDeletingUser(false));
    dispatch(
      openSnackBar({
        message: `Successfully deleted user account!`,
        status: "success",
      })
    );
  } catch (error) {
    dispatch(setDeletingUser(false));
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const sendInvite = (email, type) => async (dispatch) => {
  try {
    const respData = await userService.sendInvite(email, type);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully sent invitation!`,
          status: "success",
        })
      );
    } else {
      dispatch(openSnackBar({ message: respData.message, status: "error" }));
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};
export const allowArtist = (userId) => async (dispatch) => {
  dispatch(setUpdatingUser(true));
  try {
    const respData = await userService.allowArtist(userId);
    dispatch(setUpdatingUser(false));
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully allowed to artist!`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(setUpdatingUser(false));
    dispatch(openSnackBar({ message: error.message, status: "error" }));
    throw new Error(error);
  }
};

export const disallowArtist = (userId) => async (dispatch) => {
  dispatch(setUpdatingUser(true));
  try {
    const respData = await userService.disallowArtist(userId);
    dispatch(setUpdatingUser(false));
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully disallowed to artist!`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(setUpdatingUser(false));
    dispatch(openSnackBar({ message: error.message, status: "error" }));
    throw new Error(error);
  }
};

export const allowSubmit = (userId) => async (dispatch) => {
  dispatch(setUpdatingUser(true));
  try {
    const respData = await userService.allowSubmit(userId);
    dispatch(setUpdatingUser(false));
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully allowed to submit!`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(setUpdatingUser(false));
    dispatch(openSnackBar({ message: error.message, status: "error" }));
    throw new Error(error);
  }
};

export const disallowSubmit = (userId) => async (dispatch) => {
  dispatch(setUpdatingUser(true));
  try {
    const respData = await userService.disallowSubmit(userId);
    dispatch(setUpdatingUser(false));
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully disallowed to submit!`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(setUpdatingUser(false));
    dispatch(openSnackBar({ message: error.message, status: "error" }));
    throw new Error(error);
  }
};

export const allowPriToken = (userId) => async (dispatch) => {
  dispatch(setUpdatingUser(true));
  try {
    const respData = await userService.allowPriToken(userId);
    dispatch(setUpdatingUser(false));
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully allowed user private token!`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(setUpdatingUser(false));
    dispatch(openSnackBar({ message: error.message, status: "error" }));
    throw new Error(error);
  }
};

export const disallowPriToken = (userId) => async (dispatch) => {
  dispatch(setUpdatingUser(true));
  try {
    const respData = await userService.disallowPriToken(userId);
    dispatch(setUpdatingUser(false));
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully disallowed user private token!`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(setUpdatingUser(false));
    dispatch(openSnackBar({ message: error.message, status: "error" }));
    throw new Error(error);
  }
};

export const resetPwd = (userId) => async (dispatch) => {
  dispatch(setUpdatingUser(true));
  try {
    const respData = await userService.resetPwd(userId);
    dispatch(setUpdatingUser(false));
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully reset user password!`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(setUpdatingUser(false));
    dispatch(openSnackBar({ message: error.message, status: "error" }));
    throw new Error(error);
  }
};

export const verifyUser = (userId) => async (dispatch) => {
  try {
    const respData = await userService.verifyUserId(userId);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully verified the user!`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
    throw new Error(error);
  }
};

export const updateVeriffUserId =
  (userId, veriffUserId) => async (dispatch) => {
    // dispatch(setUpdatingUser(true));
    try {
      const respData = await userService.updateVeriffUserId(
        userId,
        veriffUserId
      );
      // dispatch(setUpdatingUser(false));
      if (respData.status === "success") {
        dispatch(
          openSnackBar({
            message: `KYC Verification has started.`,
            status: "success",
          })
        );
        //     dispatch(setUser(respData.user))
      }
    } catch (error) {
      dispatch(setUpdatingUser(false));
      dispatch(openSnackBar({ message: error.message, status: "error" }));
      throw new Error(error);
    }
  };

export const updateTooltip = (type, userId) => async (dispatch) => {
  dispatch(setUpdatingUser(true));
  try {
    const respData = await userService.updateTooltip(type, userId);
    dispatch(setUpdatingUser(false));
    if (respData.status === "success") {
      dispatch(setUser(respData.user));
    }
  } catch (error) {
    console.log("updateTooltip", type, userId);
    dispatch(setUpdatingUser(false));
    dispatch(openSnackBar({ message: error.message, status: "error" }));
    throw new Error(error);
  }
};

//selectors
export const selectUser = (state) => state.userState.user;
export const selectGetUser = (state) => state.userState.get_user;
export const selectUserRole = (state) =>
  state.userState.user && state.userState.user.role;
export const selectUpdatingUser = (state) => state.userState.updatingUser;
export const selectGettingUserData = (state) => state.userState.gettingUserData;
export const selectUserArtworks = (state) => state.userState.artworks;
export const selectSubmitted = (state) => state.userState.submitted;
export const selectCollectorTooltip = (state) =>
  state.userState.collectorTooltip;

export default userSlice.reducer;
