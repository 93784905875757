import { createSlice } from '@reduxjs/toolkit';
import { searchService } from '../services/search.service';
import { openSnackBar } from './snackBarReducer';

//reducers
export const searchSlice = createSlice({
    name: 'search',
    initialState: {
        searching: false,
        type: 'artwork',
        artworks: {},
        artists: {},
        carousels: [],
    },
    reducers: {
        searchRequest: (state)  => {
            state.searching = true;
        },
        searchArtworksSuccess: (state, action) => {
            state.searching = false;
            state.artworks = action.payload;
        },
        searchArtistsSuccess: (state, action) => {
            state.searching = false;
            state.artists = action.payload;
        },
        setSearchType: (state, action) => {
            state.type = action.payload;
        },
        setCarousels: (state, action) => {
            state.carousels = action.payload;
        },
    }
});

//actions
export const { searchRequest, searchArtworksSuccess, searchArtistsSuccess, setSearchType, setCarousels } = searchSlice.actions;

export const searchData = (data) => async (dispatch) => {
    dispatch(searchRequest());
    try {
        const respData = await searchService.search(data);
        if ( data.type === 'artworks' )
            dispatch(searchArtworksSuccess(respData));
        else
            dispatch(searchArtistsSuccess(respData));
    }  catch (error) {
        dispatch(openSnackBar({ message: error.message, status: 'error' }))
    }
}

export const getCarousels = () => async (dispatch) => {
    try {
        const respData = await searchService.getCarousels();
        if ( respData.status === 'success' )
            dispatch(setCarousels(respData.carousel_images));
    }  catch (error) {
        dispatch(openSnackBar({ message: error.message, status: 'error' }))
    }
}

//selectors
export const selectSearchType = (state) => state.searchState.type;
export const selectArtworksResult = (state) => state.searchState.artworks;
export const selectArtistsResult = (state) => state.searchState.artists;
export const selectCarousels = (state) => state.searchState.carousels;

export default searchSlice.reducer;