import { Routes, Route, Navigate } from "react-router-dom";
import {
  ProtectedRoute,
  UnprotectedRoute,
  ExceptionRoute,
} from "./components/protectedroute";
import { selectLoggedIn } from "./redux/authReducer";
import {
  getUserData,
  selectUserRole,
  setGettingUserData,
} from "./redux/userReducer";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Suspense } from "react";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Font Awesome Style Sheet
import "@fortawesome/fontawesome-free/css/all.min.css";
import LoadingLineSpinner from "./components/LoadingLineSpinner";

const LogIn = React.lazy(() => import("./pages/auth/login"));
const Logout = React.lazy(() => import("./pages/auth/logout"));
const Register = React.lazy(() => import("./pages/auth/register"));
const RegisterWithRole = React.lazy(() =>
  import("./pages/auth/registerwithrole")
);
const DashboardCollector = React.lazy(() =>
  import("./pages/dashboard/collector")
);
const DashboardArtist = React.lazy(() => import("./pages/dashboard/artist"));
const DashboradAuthenticator = React.lazy(() =>
  import("./pages/dashboard/authenticator")
);
const ArtworkEdit = React.lazy(() => import("./pages/artwoks/edit"));
const ArtWorkDetails = React.lazy(() => import("./pages/artwoks/details"));
const ScanDetails = React.lazy(() => import("./pages/scans/details"));

const Agreement = React.lazy(() => import("./pages/agreement/Agreement"));
const Royalty = React.lazy(() => import("./pages/royalty"));

const UserProfileEdit = React.lazy(() => import("./pages/user/edit"));
const UserProfileOtherEdit = React.lazy(() => import("./pages/user/editOther"));
const Notification = React.lazy(() => import("./pages/user/notification"));
const ForgotPassword = React.lazy(() => import("./pages/auth/forgotpassword"));
const ResetPassword = React.lazy(() => import("./pages/auth/reset"));
const VerifyUser = React.lazy(() => import("./pages/auth/verify"));
const Artists = React.lazy(() => import("./pages/search/artists"));
const Artworks = React.lazy(() => import("./pages/search/artworks"));
const UserProfileDetail = React.lazy(() => import("./pages/user/detail"));
const UserProfileOtherDetail = React.lazy(() =>
  import("./pages/user/detailOther")
);

const KYCPage = React.lazy(() => import("./pages/kyc"));
const BillingPage = React.lazy(() => import("./pages/billing"));
const Normal = React.lazy(() => import("./pages/dashboard/normal"));
const Info = React.lazy(() => import("./pages/dashboard/info"));
const BlogEdit = React.lazy(() => import("./pages/blog/edit"));
const BlogDetail = React.lazy(() => import("./pages/blog/detail"));
const BlogNews = React.lazy(() => import("./pages/blog"));
const Exhibition = React.lazy(() => import("./pages/exhibition"));
const ExhibitionEdit = React.lazy(() => import("./pages/exhibition/edit"));
const HomePage = React.lazy(() => import("./pages/new"));

const AppLayout = () => {
  const isLoggedIn = useSelector(selectLoggedIn);
  const userRole = useSelector(selectUserRole);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      try {
        dispatch(getUserData(JSON.parse(localStorage.user).user.id));
      } catch (error) {
        console.error(error);
      }
    } else {
      dispatch(setGettingUserData(false));
    }
  }, [isLoggedIn, dispatch]);

  return (
    <div className="min-h-screen bg-ato-form-border bg-opacity-10">
      <Suspense fallback={<LoadingLineSpinner />}>
        <ToastContainer theme="colored" />
        <Routes>
          {/* <Route path="/" element={
              isLoggedIn?
              (userRole === 'authenticator' ? <Dashborad_Authenticator /> : (userRole === 'artist' ? <Dashboard_Artist /> : <Dashboard_Collector />))
              :
              <Navigate to="/login" replace />
            }
          /> */}
          <Route path="/" element={<HomePage />} />
          <Route path="/search" element={<Artworks />} />
          <Route
            path="/dashboard"
            element={
              isLoggedIn ? (
                <ProtectedRoute>
                  {userRole === "authenticator" ? (
                    <DashboradAuthenticator />
                  ) : userRole === "artist" ? (
                    <DashboardArtist />
                  ) : (
                    <DashboardCollector />
                  )}
                </ProtectedRoute>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route path="/info" element={<Info />} />
          <Route
            path="/login"
            element={
              <UnprotectedRoute>
                <LogIn />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/register"
            element={
              <UnprotectedRoute>
                <Register />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/register/:role"
            element={
              <UnprotectedRoute>
                <RegisterWithRole />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/register/:role/:token"
            element={
              <UnprotectedRoute>
                <RegisterWithRole />
              </UnprotectedRoute>
            }
          />
          <Route path="/logout" element={<Logout />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/reset/:token" element={<ResetPassword />} />
          <Route path="/verify/:token" element={<VerifyUser />} />
          <Route
            path="/billing"
            element={
              <ProtectedRoute>
                <BillingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/kyc"
            element={
              <ProtectedRoute>
                <KYCPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/artwork/edit/:id"
            element={
              <ProtectedRoute>
                <ArtworkEdit />
              </ProtectedRoute>
            }
          />
          <Route path="/search" element={<Artworks />} />
          <Route path="/search/artists" element={<Artists />} />
          <Route
            path="/authenticators/:id"
            element={<UserProfileOtherDetail />}
          />
          <Route path="/artists/:url" element={<UserProfileDetail />} />
          <Route
            path="/user/edit"
            element={
              <ProtectedRoute>
                {userRole !== "artist" ? (
                  <UserProfileOtherEdit />
                ) : (
                  <UserProfileEdit />
                )}
              </ProtectedRoute>
            }
          />
          <Route
            path="/artist/edit/:id"
            element={
              <ProtectedRoute>
                <UserProfileEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/artwork/detail/:id/:query"
            element={<ArtWorkDetails />}
          />
          <Route
            path="/artwork/scan/:id/:query"
            element={
              // <ProtectedRoute>
              <ScanDetails />
              // </ProtectedRoute>
            }
          />
          <Route path="/news/edit/:id" element={<BlogEdit />} />
          <Route path="/news/detail/:key" element={<BlogDetail />} />
          <Route path="/news" element={<BlogNews />} />
          <Route path="/exhibitions" element={<Exhibition />} />
          <Route path="/exhibitions/edit/:id" element={<ExhibitionEdit />} />

          <Route
            path="/royaltycalculator"
            element={
              <ProtectedRoute>
                <Royalty />
              </ProtectedRoute>
            }
          />
          <Route
            path="/agreement/:role"
            element={
              <UnprotectedRoute>
                <Agreement />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/agreement/:role/:token"
            element={
              <UnprotectedRoute>
                <Agreement />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <ProtectedRoute>
                <Notification />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<ExceptionRoute></ExceptionRoute>} />
          <Route path="/home" element={<Normal />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default AppLayout;
