import { authHeader, handleResponse } from "../utils/utils";
import { API_BASE } from "../config/constants";

const getNotification = async (id) => {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = fetch(`${API_BASE}/notify/${id}`, requestOptions)
    return await handleResponse(response);
}

const readNotification = async (id) => {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = fetch(`${API_BASE}/notify/${id}`, requestOptions)
    return await handleResponse(response);
}

export const notificationService = {
    getNotification,
    readNotification,
};
