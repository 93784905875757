import { authHeader, handleResponse } from "../utils/utils";
import { API_BASE } from "../config/constants";

const search = async (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const response = fetch(`${API_BASE}/search`, requestOptions)
    return await handleResponse(response);
}

const getCarousels = async () => {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = fetch(`${API_BASE}/search/carousels`, requestOptions)
    return await handleResponse(response);
}

export const searchService = {
    search,
    getCarousels,
};
