import { createSlice } from "@reduxjs/toolkit";
import { artService } from "../services/art.service";
import { openSnackBar } from "./snackBarReducer";

//reducers
export const artworkSlice = createSlice({
  name: "artwork",
  initialState: {
    uploadingArtwork: false,
    uploadedArtwork: false,
    verifiedArtwork: false,
    artworks: [],
    artworkFilter: "",
    artworkFilterOrder: false,
    artistFilter: "",
    artistFilterOrder: false,
    artwork_id: 0,
    uploadStatus: false,

    pageNum: 0,
  },
  reducers: {
    uploadRequest: (state) => {
      state.uploadingArtwork = true;
    },
    uploadSuccess: (state, action) => {
      state.uploadingArtwork = false;
      state.uploadedArtwork = true;
      state.uploadStatus = action.payload;
    },
    finishUploadedRequest: (state) => {
      state.uploadingArtwork = false;
      state.uploadedArtwork = false;
    },
    verifyRequest: (state) => {
      state.verifiedArtwork = false;
    },
    verifySuccess: (state) => {
      state.verifiedArtwork = true;
    },
    setArtworks: (state, action) => {
      state.artworks = action.payload;
    },
    setArtworkFilter: (state, action) => {
      state.artworkFilter = action.payload.filter;
      state.artworkFilterOrder = action.payload.order;
    },
    setArtistFilter: (state, action) => {
      state.artistFilter = action.payload.filter;
      state.artistFilterOrder = action.payload.order;
    },
    setUploadedID: (state, action) => {
      state.artwork_id = action.payload;
    },

    setPageNum: (state, action) => {
      state.pageNum = action.payload;
    },
  },
});

//actions
export const {
  uploadRequest,
  uploadSuccess,
  finishUploadedRequest,
  verifyRequest,
  verifySuccess,
  setArtworks,
  setArtworkFilter,
  setArtistFilter,
  setUploadedID,
  setPageNum,
} = artworkSlice.actions;

export const getArtworks = (type) => async (dispatch) => {
  try {
    const respData = await artService.getArtworks(type);
    if (respData.status === "success") {
      dispatch(setArtworks(respData.artworks));
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const uploadArtwork = (id, data) => async (dispatch) => {
  dispatch(uploadRequest);
  try {
    const respData = await artService.uploadArtwork(id, data);
    if (respData.status === "success") {
      dispatch(setUploadedID(respData.artwork._id));
      dispatch(uploadSuccess(true));
    } else {
      dispatch(openSnackBar({ message: respData.message, status: "error" }));
      dispatch(uploadSuccess(false));
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const submitBillDoc = (id, data) => async (dispatch) => {
  try {
    const respData = await artService.uploadBillDoc(id, data);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully submit purchase information for the artwork`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const updatePolice = (id, data) => async (dispatch) => {
  try {
    const respData = await artService.updatePolice(id, data);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({ message: `Successfully submitted`, status: "success" })
      );
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const updateInsurance = (id, data) => async (dispatch) => {
  try {
    const respData = await artService.updateInsurance(id, data);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({ message: `Successfully submitted`, status: "success" })
      );
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const updateStolen = (id, data) => async (dispatch) => {
  try {
    const respData = await artService.updateStolen(id, data);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({ message: `Successfully submitted`, status: "success" })
      );
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const updateSold = (id, data) => async (dispatch) => {
  try {
    const respData = await artService.updateSold(id, data);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({ message: `Successfully submitted`, status: "success" })
      );
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const updateHistory = (id, data) => async (dispatch) => {
  try {
    const respData = await artService.updateHistory(id, data);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({ message: `Successfully submitted`, status: "success" })
      );
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const updateMIA = (id, data) => async (dispatch) => {
  try {
    const respData = await artService.updateMIA(id, data);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({ message: `Successfully submitted`, status: "success" })
      );
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const votePurchase = (id) => async (dispatch) => {
  try {
    const respData = await artService.votePurchase(id);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully vote the purchase information`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const voteArtist = (id) => async (dispatch) => {
  try {
    const respData = await artService.voteArtist(id);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully vote the artist profile`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const disvoteArtist = (id) => async (dispatch) => {
  try {
    const respData = await artService.disvoteArtist(id);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully disvote the artist profile`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const toggleArtist = (id) => async (dispatch) => {
  try {
    const respData = await artService.toggleArtist(id);
    if (respData.status === "success") {
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const reportArtist = (id, message) => async (dispatch) => {
  try {
    const respData = await artService.reportArtist(id, message);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully report the artist profile`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const changeCheckPurchase = (id, status) => async (dispatch) => {
  try {
    const respData = await artService.changeCheckPurchase(id, status);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully ${
            status ? "allowed" : "disallowed"
          } the authenticator.`,
          status: "success",
        })
      );
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const changeAdmin = (id, status) => async (dispatch) => {
  try {
    const respData = await artService.changeAdmin(id, status);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: `Successfully ${
            status ? "allowed" : "disallowed"
          } the admin.`,
          status: "success",
        })
      );
    } else {
      dispatch(openSnackBar({ message: respData.message, status: "error" }));
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const changeSalePrice = (id, price, type) => async (dispatch) => {
  try {
    const respData = await artService.changeSalePrice(id, price);
    if (respData.status === "success") {
      const respData = await artService.getArtworks(type);
      if (respData.status === "success") {
        dispatch(setArtworks(respData.artworks));
      }
      dispatch(
        openSnackBar({
          message: `Successfully updated the sale price to ${price}.`,
          status: "success",
        })
      );
    } else {
      dispatch(openSnackBar({ message: respData.message, status: "error" }));
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const sendReviewArtwork = (id, status) => async (dispatch) => {
  try {
    var respData = await artService.sendReviewArtwork(id, status);
    dispatch(
      openSnackBar({
        message: status
          ? `Successfully sent for review`
          : `Successfully sent edit request`,
        status: "success",
      })
    );
    respData = await artService.getArtworks("user");
    if (respData.status === "success") {
      dispatch(setArtworks(respData.artworks));
    } else {
      dispatch(openSnackBar({ message: respData.message, status: "error" }));
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
    throw new Error(error);
  }
};

export const deleteArtwork = (id) => async (dispatch) => {
  try {
    var respData = await artService.deleteArtwork(id);
    dispatch(
      openSnackBar({
        message: `Successfully deleted the artwork`,
        status: "success",
      })
    );
    respData = await artService.getArtworks("user");
    if (respData.status === "success") {
      dispatch(setArtworks(respData.artworks));
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
    throw new Error(error);
  }
};

export const finishUploaded = () => (dispatch) => {
  dispatch(finishUploadedRequest());
};

export const verifyArtwork = (id) => async (dispatch) => {
  dispatch(verifyRequest);
  try {
    const respData = await artService.verifyArtwork(id);
    if (respData.status === "success") {
      dispatch(openSnackBar({ message: "Voted approve!", status: "info" }));
      dispatch(verifySuccess());
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const disvoteArtwork = (id) => async (dispatch) => {
  try {
    const respData = await artService.disvoteArtwork(id);
    if (respData.status === "success") {
      dispatch(openSnackBar({ message: "Voted deny!", status: "info" }));
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const toggleRecommend = (id) => async (dispatch) => {
  try {
    const respData = await artService.toggleRecommend(id);
    if (respData.status === "success") {
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const toggleCarousel = (id) => async (dispatch) => {
  try {
    const respData = await artService.toggleCarousel(id);
    if (respData.status === "success") {
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const reportArtwork = (id, message) => async (dispatch) => {
  try {
    const respData = await artService.reportArtwork(id, message);
    if (respData.status === "success") {
      dispatch(
        openSnackBar({
          message: "Voted request more information!",
          status: "info",
        })
      );
    }
  } catch (error) {
    dispatch(openSnackBar({ message: error.message, status: "error" }));
  }
};

export const handleEditRequest =
  (art_id, admin_id, status) => async (dispatch) => {
    try {
      const respData = await artService.handleEditRequest(
        art_id,
        admin_id,
        status
      );
      if (respData.status === "success") {
        dispatch(
          openSnackBar({
            message: status
              ? `Successfully approved request`
              : `Successfully denied request`,
            status: "success",
          })
        );
      }
    } catch (error) {
      dispatch(openSnackBar({ message: error.message, status: "error" }));
    }
  };

export const handleChangeHiddenStatus =
  (artwork_id, status) => async (dispatch) => {
    try {
      const respData = await artService.changeHiddenStatus(artwork_id, status);

      if (respData.status === "success") {
        dispatch(
          openSnackBar({
            message: status
              ? `Show artwork successfully`
              : `Hide artwork successfully`,
            status: "success",
          })
        );
      }
    } catch (err) {
      dispatch(openSnackBar({ message: err.message, status: "error" }));
    }
  };

export const handleChangeHiddenPriceStatus =
  (artwork_id, status) => async (dispatch) => {
    try {
      const respData = await artService.changeHiddenPriceStatus(
        artwork_id,
        status
      );

      if (respData.status === "success") {
        dispatch(
          openSnackBar({
            message: status
              ? `Show artwork price successfully`
              : `Hide artwork price successfully`,
            status: "success",
          })
        );
      }
    } catch (err) {
      dispatch(openSnackBar({ message: err.message, status: "error" }));
    }
  };
//selectors
export const selectUploaded = (state) => state.artworkState.uploadedArtwork;
export const selectVerified = (state) => state.artworkState.verifiedArtwork;
export const selectArtworks = (state) => state.artworkState.artworks;
export const selectArtworkFilter = (state) => state.artworkState.artworkFilter;
export const selectArtistFilter = (state) => state.artworkState.artistFilter;
export const selectArtworkFilterOrder = (state) =>
  state.artworkState.artworkFilterOrder;
export const selectArtistFilterOrder = (state) =>
  state.artworkState.artistFilterOrder;
export const selectUploadedID = (state) => state.artworkState.artwork_id;
export const selectUploadStatus = (state) => state.artworkState.uploadStatus;
export const selectPageNum = (state) => state.artworkState.pageNum;

export default artworkSlice.reducer;
