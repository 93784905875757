import React from "react";
import { useLocation } from "react-router-dom";
import { API_URL } from "../config/constants";

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function authHeader() {
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.token) {
    return {
      Authorization: "Bearer " + user.token,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
    };
  } else {
    return {};
  }
}

export const handleResponse = async (response, onError) => {
  const res = await response;
  const text = await res.text();

  if (!!text && JSON.parse(text)) {
    const data = text && JSON.parse(text);
    if (!res.ok) {
      if (res.status === 401 && onError) {
        onError();
      }

      const error = (data && data.message) || res.statusText;
      throw new Error(error);
    }

    return data;
  } else {
    return null;
  }
};

export const getImagePath = (file) => {
  return API_URL + "/uploads/" + file.path;
};

export const getResolvedPath = (file) => {
  try {
    // return API_URL + '/files/' + file.path + '/' + file.name;
    return (
      "https://d7hftxdivxxvm.cloudfront.net/?resize_to=fit&amp;src=" +
      API_URL +
      "/files/" +
      file.path +
      "&amp;width=445&amp;height=556&amp;quality=80"
    );
  } catch (err) {
    console.log(err);
    return "";
  }
};

export const getAttachPath = (file) => {
  try {
    return API_URL + "/files/" + file.path + "/" + file.name;
  } catch (err) {
    console.log(err);
    return "";
  }
};

export const checkIsAdmin = (user) => {
  const admin_list = process.env.REACT_APP_ADMIN_USERS.split(",");
  return admin_list.indexOf(user) >= 0;
};

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const getArtworkQuery = (artwork, artist) => {
  const query =
    artwork.name.toLowerCase().replace(/[^A-Z0-9]/gi, "-") +
    "-" +
    artist.firstname.toLowerCase().replace(/[^A-Z0-9]/gi, "-") +
    "-" +
    artist.lastname.toLowerCase().replace(/[^A-Z0-9]/gi, "-");
  return query;
};

export const short_string = (text) => {
  return text.length > 50 ? text.substr(0, 50) + "..." : text;
};
